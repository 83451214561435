<!-- 进账页面 -->
<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="income-container">
    <div class="title-container">
      <div>进账列表</div>
      <el-button class="common-screen-btn" @click="() => download()">导出</el-button>
    </div>

    <div class="common-padding">
    <!-- 头部数据统计 -->
    <div class="common-Summary-container">
      <div style="display:flex;flex-direction: column;width: 100%">
        <div style="display:flex;align-items: center;">
          <span class="income">订单收入</span>
      <div class="Summary-item no-width">
        <div class="item-title">订单应收金额</div>
        <div
          @click="
            () => {
              collect(
                'income-totalPrice',
                '订单应收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '应收金额', colProp: ['price'], tableHead: 'priceTotal' },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '备注', colProp: ['note'] }
                ]
              );
            }
          "
          class="item-num greenFt"
        >
          {{ parseFloat(headerData.totalPrice).toFixed(2) || "0" }}
        </div>
      </div>

      <div class="Summary-item no-width">
        <div class="item-title">订单实收金额</div>
        <div
          @click="
            () => {
              collect(
                'income-collectionPrice',
                '订单实收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '实收金额', colProp: ['collectionAmount'], tableHead: 'collectionAmountTotal' },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '备注', colProp: ['note'] }
                ]
              );
            }
          "
          class="item-num greenFt"
        >
          {{ parseFloat(headerData.collectionPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <div class="item-title">订单未收金额</div>
        <div
          @click="
            () => {
              collect(
                'income-unCollectionPrice',
                '订单未收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '未收金额', colProp: ['price'], tableHead: 'unCollectionPriceTotal' },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '备注', colProp: ['note'] }
                ]
              );
            }
          "
          class="item-num greenFt"
        >
          {{ parseFloat(headerData.uncollectedPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          content="历史欠款订单金额 = 非本月订单应收金额 - 非本月订单实收金额(非本月订单是指创建时间非当月的订单)
          订单服务时间结束，财务未完成确认收款的订单金额为历史欠款金额
          "
          placement="bottom"
          enterable
        >
          <div class="item-title">
            历史欠款金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'income-historyPrice',
                '历史欠款订单金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                   { name: '历史欠款订单金额', colProp: ['price'], tableHead: 'historyPriceTotal' },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '订单收款方式', colProp: ['paymentMethods'] },
                  { name: '备注', colProp: ['note'] }
                ]
              );
            }
          "
          class="item-num greenFt"
        >
          <!-- <el-button
            type="text"
            @click="
              () => {
                moneyShow('历史欠款金额');
              }
            "
            > -->
          {{ parseFloat(headerData.historyUncollectedPrice).toFixed(2) || "0" }}
          <!-- </el-button> -->
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          :content="`【说明】月度订单产能金额 = 订单总价 *（月度已服务天数/总服务天数）\n【说明】累计订单产能金额 = 月度订单产能金额累加`"
          placement="bottom"
          enterable
        >
          <div class="item-title">
            订单产能金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'income-capacityPrice',
                '订单产能金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                   { name: '订单产能金额', colProp: ['price'], tableHead: 'salesSalaryTotal' },
                  { name: '销售负责人', colProp: ['createName'] },
                  { name: '备注', colProp: ['note'] }
                ]
              );
            }
          "
          class="item-num greenFt"
        >
          {{ parseFloat(headerData.capacityPrice).toFixed(2) || "0" }}
        </div>
      </div>
        </div>
        <!-- 接单收入、、、、、、、、、、、、、、、、、、、、、 -->
        <div style="display:flex;align-items: center;">
          <span class="income">接单收入</span>
      <div class="Summary-item no-width">
        <div class="item-title">接单应收金额</div>
        <div
          @click="
            () => {
              collect(
                'disTotalPrice',
                '接单应收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '应收金额', colProp: ['disTotalPrice'], tableHead: 'disPriceTotal' },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                'dis'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(headerData.disTotalPrice).toFixed(2) || "0" }}
        </div>
      </div>

      <div class="Summary-item no-width">
        <div class="item-title">接单实收金额</div>
        <div
          @click="
            () => {
              collect(
                'disCollectionPrice',
                '接单实收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '实收金额', colProp: ['disCollectionPrice'], tableHead: 'disCollectionPriceTotal' },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                'dis'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(headerData.disCollectionPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <div class="item-title">接单未收金额</div>
        <div
          @click="
            () => {
              collect(
                'disUncollectedPrice',
                '接单未收金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '未收金额', colProp: ['disUncollectedPrice'], tableHead: 'disUncollectedPriceTotal' },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                'dis'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(headerData.disUncollectedPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          content="历史欠款接单金额=非本月接单应收金额-非本月接单实收金额（非当月接单是指接单接收时间非当月的接单）
          订单服务时间结束，财务未完成确认收款的订单金额为历史欠款金额
          "
          placement="bottom"
          enterable
        >
          <div class="item-title">
            历史欠款接单金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'disHistoryUncollectedPrice',
                '历史欠款接单金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '历史欠款接单金额', colProp: ['disHistoryUncollectedPrice'], tableHead: 'disHistoryUncollectedPriceTotal' },
                  { name: '订单收款方式', colProp: ['paymentMethods'] },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                'dis'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(headerData.disHistoryUncollectedPrice).toFixed(2) || "0" }}
        </div>
      </div>
      <div class="Summary-item no-width">
        <el-tooltip
          class="item"
          effect="dark"
          :content="`【说明】月度接单产能金额=结算金额 *【（月度店铺a已服务天数/店铺总服务天数）+（月度店铺b已服务天数/店铺总服务天数）】+...\n【说明】累计接单产能金额 = 月度接单产能金额累加`"
          placement="bottom"
          enterable
        >
          <div class="item-title">
            接单产能金额<img
              src="../../assets/images/question.png"
              class="tit-img"
            />
          </div>
        </el-tooltip>
        <div
          @click="
            () => {
              collect(
                'disCapacityPrice',
                '接单产能金额',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNo' },
                  { name: '客户名称', type: 'input', modelKey: 'customerName' },
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                  { name: '时间筛选', type: 'date', modelKey: 'startEnd' },
                ],
                [
                  { name: '订单编号', colProp: ['disOrderCode'] },
                  { name: '订单名称', colProp: ['disOrderName'] },
                  { name: '客户名称', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime', 'endTime'] },
                  { name: '接单产能金额', colProp: ['disCapacityPrice'], tableHead: 'disCapacityPriceTotal' },
                  { name: '创建人', colProp: ['createName'] },
                  { name: '备注', colProp: ['remark'] }
                ],
                'dis'
              );
            }
          "
          class="item-num orangeFt"
        >
          {{ parseFloat(headerData.disCapacityPrice).toFixed(2) || "0" }}
        </div>
      </div>
        </div>
        </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>订单名称/编号:</span>
        <el-input
          v-model="params.keyword"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="
            (val) => {
              remoteMethod(val);
            }
          "
        >
          <el-option
            v-for="item in custList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单收款方式:</span>
        <el-select
          class="common-screen-input"
          v-model="params.paymentMethods"
          placeholder="请选择"
          @change="commonFun"
          clearable
          filterable
        >
          <el-option
            v-for="item in payType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="common-input-container">
        <span>销售负责人:</span>
        <el-input
          v-model="params.createName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <br />
      <div class="common-input-container">
        <span>收款状态:</span>
        <el-select
          v-model="params.financialStatus"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单类型:</span>
        <el-select
          v-model="params.orderType"
          placeholder="请选择"
          class="common-screen-input"
          @change="commonFun"
          clearable
        >
          <el-option
            v-for="item in options4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <br/>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <!-- 表格 -->
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="orderNo"
        label="订单编号"
        :formatter="tableColumn"
        fixed="left"
        width="130"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getDetails(scope.row)"
            >{{ scope.row.orderNo || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="orderName"
        label="订单名称"
        :formatter="tableColumn"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="customerName"
        label="客户名称"
        :formatter="tableColumn"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="orderType"
        label="订单类型"
        :formatter="tableColumn"
        width="150"
      >
      <template slot-scope="scope">
        {{scope.row.orderType == 2 ? '平台派单':'自主成单'}}
      </template>
      </el-table-column>
      <el-table-column
        prop="sericeTime"
        label="服务时间"
        :formatter="tableColumn"
        width="200"
      ></el-table-column>
      <el-table-column prop="financialStatus" label="收款状态" width="120px">
        <template slot-scope="scope">
          <span
            :style="{
              color:
                scope.row.financialStatus == 'pending'
                  ? '#F98787'
                  : scope.row.financialStatus == 'confirming'
                  ? '#F09009'
                  : '#1890FF'
            }"
          >
            {{
              scope.row.financialStatus == "pending"
                ? "待销售催款"
                : scope.row.financialStatus == "confirming"
                ? "待财务确认"
                : "交易完成"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="订单收款方式" width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.paymentMethods">
            <div v-if="scope.row.paymentMethods == '前置收款'" >
              <span class="common-label1">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>前置收款
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '前置服务'">
              <span class="common-label2">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>前置服务
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '免费试用'">
              <span class="common-label3">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>免费试用
              </span>
              </div>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="产能金额" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.salesSalary || '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="collectionRate"
        label="收款比例"
        :formatter="tableColumn"
        width="120px"
      >
        <template slot-scope="scope">
          {{ scope.row.collectionRate ? (scope.row.collectionRate).toFixed(2) : '0.00' }}%
        </template>
      </el-table-column>
      <el-table-column prop="originalPrice" label="订单原始价格" :formatter="tableColumn" width="120px"></el-table-column>
      <el-table-column prop="differencePrice" label="订单调价价格" :formatter="tableColumn" width="120px"></el-table-column>
      <el-table-column prop="price" label="订单应收金额" :formatter="tableColumn" width="120px">
        <template slot-scope="scope">
          {{ scope.row.price ? (scope.row.price).toFixed(2) : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="collectionAmount" label="实收金额" :formatter="tableColumn">
        <template slot-scope="scope">
          {{ scope.row.collectionAmount ? (scope.row.collectionAmount).toFixed(2) : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="uncollectionAmount" label="未收金额" :formatter="tableColumn">
        <template slot-scope="scope">
          {{ scope.row.uncollectionAmount ? (scope.row.uncollectionAmount).toFixed(2) : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createName"
        label="销售负责人"
        :formatter="tableColumn"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        :show-overflow-tooltip="true"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        :formatter="tableColumn"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <!-- <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                newAddEdit(scope.row);
              }
            "
            >新增收款记录</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>

    <!-- 新增弹框 -->
    <el-dialog
      title="新增收款"
      :visible.sync="newAddVisible"
      width="520px"
      :append-to-body="true"
    >
      <el-form ref="form" :model="seeMoreForm" label-position="left">
        <el-form-item label="订单总额" label-width="70px">
          <div>{{ 1 + 1 }} <span class="unit">元</span></div>
        </el-form-item>
        <el-form-item label="应收金额" label-width="70px">
          <div>
            {{ 1 + 2 }} <span class="unit">元</span
            ><span class="unit">(未收金额)</span>
          </div>
        </el-form-item>
        <el-form-item label="实收金额" label-width="70px">
          <el-input v-model="seeMoreForm.name" class="ipt2"></el-input>元
        </el-form-item>
        <el-form-item label="收款方式" label-width="70px">
          <el-select
            v-model="seeMoreForm.mode"
            placeholder="请选择收款方式"
            class="ipt"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知人" label-width="70px">
          <el-select
            v-model="seeMoreForm.mode"
            multiple
            placeholder="请选择通知人"
            class="ipt"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" label-width="70px">
          <el-input
            v-model="seeMoreForm.name"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="70px">
          <div>
            <div
              v-for="(item, index) in filesList"
              :key="index"
              class="fileNames"
            >
              <div style="display: flex;justify-content: space-between;">
                <div
                  style="display: flex;justify-content: space-between;align-items: center;"
                >
                  <img
                    v-if="item.fileType == 'img'"
                    style="margin-right: 8px"
                    src="../../assets/images/img_icon.svg"
                  />
                  <img
                    v-if="item.fileType != 'img'"
                    style="margin-right: 8px"
                    src="../../assets/images/file_icon.svg"
                  />
                  {{ item.fileName }}
                </div>
                <span
                  style="cursor: pointer;color: #1890FF;"
                  @click="() => deleteFile(item.fileId)"
                >
                  删除
                </span>
              </div>
            </div>
            <div class="con-rec">
              <div>
                <div class="addFileBtn">
                  <img
                    style="margin-right: 8px"
                    src="../../assets/images/add_file_icon.svg"
                  />
                  上传附件
                  <input
                    class="addFileInput"
                    :multiple="false"
                    @input="chooseFile"
                    type="file"
                  />
                  <span class="uploadimg">
                    上传文件及图片，大小不超过2M
                  </span>
                </div>
              </div>
            </div>
            <el-checkbox v-model="checked">是否开票</el-checkbox>
          </div>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="发票类型" label-width="98px">
          <el-radio-group v-model="radio">
            <el-radio :label="1">增值普票</el-radio>
            <el-radio :label="2">增值专票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="名称" label-width="98px">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号" label-width="98px">
          <el-input v-model="seeMoreForm.name"></el-input>
        </el-form-item>
        <el-form-item label="地址、电话" label-width="98px">
          <el-input
            v-model="seeMoreForm.name"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户行及账号" label-width="98px">
          <el-input
            v-model="seeMoreForm.name"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件信息" label-width="98px">
          <el-input
            v-model="seeMoreForm.name"
            type="textarea"
            :rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newAddVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              sendRequest();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看欠款 -->
    <el-dialog
      :title="moneydialogTitle"
      :visible.sync="monDialog"
      width="30%"
      :append-to-body="true"
      :before-close="monClose"
    >
      <el-table :data="monTableData" style="width: 100%">
        <el-table-column
          prop="orderId"
          label="订单编号"
          :formatter="tableColumn"
        >
        </el-table-column>
        <!-- <el-table-column prop="name" label="订单名称" width="180">
        </el-table-column> -->
        <el-table-column
          prop="customerName"
          label="客户名称"
          :formatter="tableColumn"
        >
        </el-table-column>
        <!-- <el-table-column prop="address" label="服务时间"> </el-table-column> -->
        <el-table-column
          :formatter="tableColumn"
          prop="historyUnCollectionPrice"
          :label="moneydialogTitle"
        >
        </el-table-column>
        <!-- <el-table-column prop="address" label="销售负责人"> </el-table-column> -->
        <!-- <el-table-column prop="address" label="备注"> </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="(e) => pageFun2(e, 'pageSize')"
        @current-change="(e) => pageFun2(e)"
        style="text-align: center"
        :page-sizes="pagination2.pageSizes"
        :page-size="params2.pageSize"
        :current-page="params2.page"
        layout="total, prev, pager, next,sizes, jumper"
        :total="pagination2.total"
      >
      </el-pagination>
    </el-dialog>
    
    <income-detail
      ref="incomeDetail"
      :tableRow2="tableRow2"
      :drawer="drawer"
      :customerInfo="customerInfo"
      :chanceFollowList="chanceFollowList"
      @handleClose="handleClose"
      @loadFun="loadFun"
      @custFishing="custFishing"
      @updateChild="getDetails"
      @updateChild2="getIncomeList"
      @closeChild="closeChild"
      :loading="loading"
    ></income-detail>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="report"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
    <!-- 接单头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog2"
      :methodFuc="getFinancialReportList"
      :commonSumVisible="commonSumVisible2"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import IncomeDetail from "./children/incomeDetail.vue";
import { tableColumn, DateTransform, Config } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate";
import { upload } from "../../service/upload.js";
import { getCustList } from "../../service/common";
import CommonSumDialog from "../../components/common/commonSumDialog.vue";
import {
  getIncomeTitle,
  getRenewalList,
  getHistoryList,
  getFinancialReportList
} from "../../service/money";
import { report } from "../../service/home.js";
import { mapState } from "vuex";

export default {
  name: "Income",
  components: {
    Breadcrumb,
    IncomeDetail,
    CommonDate,
    CommonSumDialog
  },
  props: {},
  data() {
    return {
      tableColumn,
      DateTransform,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "财务", isLink: false },
        { title: "进账列表", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10,
        financialStatus: "all"
      },
      pagination2: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params2: {
        page: 1,
        pageSize: 10
      },
      datePicker: [], //日期控制绑定值
      datevalue: "1", //选中的下拉value
      datetype: "1",
      dateMode: "日", //控制范围组件显示样式
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      dialogVisible: false,
      newAddVisible: false, //新增弹框
      seeMoreForm: {
        name: "",
        mode: "" //收款方式
      },
      tableData: [],
      options: [
        {
          value: "all",
          label: "全部"
        },
        {
          value: "pending",
          label: "待销售催款"
        },
        {
          value: "confirming",
          label: "待财务确认"
        },
        {
          value: "complete",
          label: "交易完成"
        }
      ],
      options4: [
        {
          value: 1,
          label: "自主成单"
        },
        {
          value: 2,
          label: "平台派单"
        }
      ],
      timeoptions: [
        {
          value: "1",
          label: "按天"
        },
        {
          value: "2",
          label: "按月"
        },
        {
          value: "3",
          label: "按年"
        }
      ],
      tableRow2: [],
      checked: true,
      radio: 1,
      loading: false,
      drawer: false, // 新增员工弹窗
      custDict: { "1": [], "2": [], "4": [], "6": [], "8": [] }, // 字典值
      // tableRow: "", // 客户id用于编辑/查看
      customerInfo: "", // 客户详情
      chanceFollowList: "", // 跟进list
      filesList: [], //上传文件
      headerData: {}, //头部数据
      custList: [], // 所属客户下拉
      monDialog: false,
      monTableData: [],
      moneydialogTitle: "",
      // 汇总弹窗所需数据
      commonSumVisible: false,
      commonSumVisible2: false,
      report, // 列表接口方法
      getFinancialReportList,// 列表接口方法2
      lineNum: "",
      payType: [
        {id: 0,name: '前置收款'},
        {id: 1,name: '前置服务'},
        {id: 2,name: '免费试用'},
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getIncomeTitle();
        this.getIncomeList();
      },400)
    },
    // 数据汇总弹窗start
    sumVisibleClose() {
      if(this.lineNum == 2){
        this.commonSumVisible2 = false;
      }else{
        this.commonSumVisible = false;
      }
    },
    collect(type, name, screen, arr,val) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      if(val){
        this.lineNum = 2
        this.$refs.commonSumDialog2.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId,
          // '',
          // '',
          // '',
          // [...getSectionDate('month')]
        );
        this.commonSumVisible2 = true
      }else{
        this.lineNum = 1
        this.$refs.commonSumDialog.getAllData(
          type,
          name,
          screen,
          arr,
          this.params.companyId,
          // '',
          // '',
          // '',
          // [...getSectionDate('month')]
        );
        this.commonSumVisible = true;
      }
    },
    // 数据汇总弹窗end
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      this.getIncomeList();
    },
    seeMore() {
      this.dialogVisible = true;
    },
    newAddEdit() {
      this.newAddVisible = true;
    },
    // 确认按钮发请求
    sendRequest() {
      this.newAddVisible = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }

      this.getIncomeList();
    },
    pageFun2(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params2.pageSize = e;
      } else {
        this.params2.page = e;
      }
      this.moneyShow();
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({ customerName: name, companyId: this.comId })).data;
      this.custList = resData;
    },
    async getIncomeList(id) {
      this.loading = true;
      if (this.datePicker && this.datePicker.length) {
        this.params.startDate = DateTransform(this.datePicker[0]);
        if (this.datePicker[1]) {
          this.params.endDate = DateTransform(this.datePicker[1]);
        }
      } else if (this.dateMode == "年") {
        this.params.startDate = DateTransform(this.datePicker);
      }
      if (id) {
        this.params.id = id;
      } else {
        delete this.params.id;
      }
      let params = { ...this.params };

      const { data } = await getRenewalList(params);
      this.tableData = data.listData.list;

      // if (data.listData.list && data.listData.list.length) {
      //   this.$refs["incomeDetail"].getIncomeDetailList(data.listData.list[0]); //表格
      // }

      this.pagination.total = data.listData.total;
      this.pagination.pageSize = data.listData.size;
      this.loading = false;
    },
    resetBtn() {
      this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.comId,
        financialStatus: "all"
      },
      this.$refs.commonReset.resetFun();
      this.getIncomeList();
      this.getIncomeTitle();
    },
    // 组件关闭前
    handleClose() {
      // this.resetBtn();
      this.getIncomeList();
      this.drawer = false;
      // 关闭新增员工弹窗
      // done();
    },
    loadFun(value) {
      this.loading = value;
    },
    // 新增收款--打开新增机会弹窗
    custFishing(row) {
      this.chanceForm = {
        customerId: row.id,
        custName: row.name
      };
      this.newAddVisible = true;
    },
    // 上传文件
    async chooseFile(e) {
      // 上传文件
      // console.log(type);
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      // let format = file.name
      let resData = (await upload({ file: file })).data;
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename
      };
      if (
        format != "bmp" &&
        format != "jpg" &&
        format != "png" &&
        format != "gif" &&
        format != "jpeg"
      ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
      console.log(resData);
    },
    deleteFile(id) {
      let filesList = [...this.filesList];
      this.filesList = filesList.filter((item) => {
        return item.fileId != id;
      });
    },
    commonFun(flag) {
      this.params.pageSize = 10;
      this.params.page = 1;

      this.getIncomeList();
      if (flag == true) {
        this.getIncomeTitle();
      }
    },
    remoteMethod(val) {
      this.getCustList(val);
    },
    async getIncomeTitle() {
      let params = { ...this.params };
      const { data } = await getIncomeTitle({ companyId: params.companyId });
      this.headerData = data;
    },
    // 点击编号跳转
    getDetails(row) {
      this.drawer = true;
      if(row.orderType == 1){
        this.$refs["incomeDetail"].getIncomeDetailList(row); //表格
        this.$refs["incomeDetail"].getOrderDetail(row); //基本信息
        this.$refs["incomeDetail"].workLog(row.id); //日志
      }else {//平台派单
        this.$refs["incomeDetail"].getIncomeDetailList2(row); //表格
        // this.$refs["incomeDetail"].getOrderDetail(row); //基本信息
        this.$refs["incomeDetail"].platWorkLog(row.id); //日志
      }
    },
    closeChild() {
      this.drawer = false;
      // this.params.financialStatus = this.params.financialStatus;
      this.getIncomeList();
    },
    async moneyShow(name) {
      let params = { ...this.params2 };
      params.companyId = this.params.companyId;
      const { data } = await getHistoryList(params);
      this.monTableData = data.list;
      // 列表分页码
      this.pagination2.total = data.total;
      this.pagination2.pageSize = data.size;
      if (name == "历史欠款金额") {
        this.moneydialogTitle = "历史欠款金额";
      }
      this.monDialog = true;
    },
    monClose() {
      this.params2 = {
        page: 1,
        pageSize: 10
      };
      this.monDialog = false;
    },
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      let url = Config.api.url + "/cms/cmsCustomerOrder/getRenewalListExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");

      window.location.href = url;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getComId()

    // this.getIncomeTitle();
    // this.getIncomeList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.common-input-container1 {
  width: 100px;
  display: inline-block;
  margin-right: 16px;
}
// .datechange{
//   width: 100%;
// }
.tit-img {
  margin-left: 3px;
  margin-top: 2px;
  cursor: pointer;
}
.ipt {
  width: 100% !important;
}
.ipt2 {
  width: 94% !important;
  margin-right: 10px;
}
.con-rec {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addFileBtn {
    height: 20px;
    color: #1890ff;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    .addFileInput {
      position: absolute;
      left: 0;
      top: 0;
      width: 120px;
      height: 20px;
      opacity: 0;
      
    }
  }
}
.uploadimg {
  font-size: 12px;
  color: #ccc;
  margin-left: 8px;
}
.unit {
  color: #999999;
  font-size: 14px;
  margin-left: 16px;
}
/deep/.el-tag.el-tag--info {
  background-color: #1890ff !important;
  border-color: #e9e9eb !important;
  color: #ffffff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  border: 1px solid #fff !important;
}
/deep/.el-tag.el-tag--info .el-tag__close {
  color: #fff;
}
/deep/.el-select .el-tag__close.el-icon-close {
  background-color: transparent;
}
/deep/.el-table .cell {
  white-space: nowrap;
}
.item-num {
  /deep/ .el-button--text {
    padding: 8px 0;
    span {
      font-size: 28px;
      color: #1890ff;
    }
  }
}
.no-width {
  width: 17%;
}
.income {
  font-size: 28px;
  color: #101010;
  margin: 0 48px;
}
.greenFt{
  color: #52C419 !important;
}
.orangeFt{
  color: #EE8E08 !important;
}
</style>
