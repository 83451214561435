import fetch from "./xhr/index";

/**
 * 首页报表接口
 * @param
 */
export const getHomePage = param => fetch("GET", "/wechatMini/homePage", param);

/**
 * 区域咨询量排名列表
 * @param
 */
export const areaRank = param => fetch("GET", "/cms/cmsCustomer/getAreaRank", param);

/**
 * 根据公司id获取操作日志列表前三条
 * @param
 */
export const selectList = param => fetch("GET", "/sys/sysOperateLog/selectList", param);

/**
 * 文档列表
 * @param
 */
export const sysFile = param => fetch("GET", "/sys/sysFile/index", param);

/**
 * 首页公告接口
 * @param
 */
export const systemNotice = param => fetch("GET", "/cms/cmsCustomer/getSystemNotice", param);

/**
 * 首页公告接口
 * @param
 */
export const report = param => fetch("GET", "/cms/cmsCustomerOrder/getReportList", param);

/**
 * 首页 销售数据统计
 * @param
 */
export const saleArea = param => fetch("GET", "/homePage/getMarketStatisticNum", param);

/**
 * 首页 销售数据概览 ---昨日、今日
 * @param
 */
export const getMarketOverviewNum = param => fetch("GET", "/homePage/getMarketOverviewNum", param);

/**
 * 首页 销售数据统计-项目图表
 * @param
 */
export const saleProjectCharts = param => fetch("GET", "/homePage/getMarketProjectNum", param);

/**
 * 首页 销售数据统计 新增数据统计 =====折线图
 * @param
 */
export const getMarketAddNum = param => fetch("GET", "/homePage/getMarketAddNum", param);

/**
 * 项目列表
 * @param
 */
export const projectList = param => fetch("POST", "/cms/cmsCustomerProject/list", param);


/**
 * 首页 销售数据统计 弹窗
 * @param
 */
export const getMarketReportList = param => fetch("GET", "/homePage/getMarketReportList", param);

/**
 * 首页 销售数据统计 设置年度目标总额
 * @param
 */
export const updateOrderTarget = param => fetch("GET", "/homePage/updateOrderTarget", param);


/**
 * 首页 客服数据统计
 * @param
 */
export const customerArea = param => fetch("GET", "/homePage/getServiceStatisticNum", param);


/**
 * 首页 客服数据统计 弹窗
 * @param
 */
export const customerUp = param => fetch("GET", "/homePage/getReportDetail", param);


/**
 * 首页 客服数据统计 ===== 折线图
 * @param
 */
export const getCustomerServiceList = param => fetch("GET", "/homePage/getCustomerServiceList", param);

/**
 * 首页 质检数据统计
 * @param
 */
export const getQualityStatisticNum = param => fetch("GET", "/homePage/getQualityStatisticNum", param);

/**
 * 首页 质检数据统计
 * @param
 */
export const getQualityProjectNum = param => fetch("GET", "/homePage/getQualityProjectNum", param);

/**
 * 首页 质检数据统计
 * @param
 */
export const getQualityReportList = param => fetch("GET", "/homePage/getQualityReportList", param);

/**
 * 首页 图表
 * @param
 */
export const getQualityShopNum = param => fetch("GET", "/homePage/getQualityShopNum", param);

/**
 * 首页 财务数据统计
 * @param
 */
export const getFinanceStatisticNum = param => fetch("GET", "/homePage/getFinanceStatisticNum", param);

/**
 * 首页 财务数据统计
 * @param
 */
export const getFinanceReportDetail = param => fetch("GET", "/homePage/getFinanceReportDetail", param);

/**
 * 首页 考勤数据统计
 * @param
 */
export const getCheckStatisticNum = param => fetch("GET", "/homePage/getCheckStatisticNum", param);

/**
 * 首页 考勤数据统计 弹窗
 * @param
 */
export const getReportDetail = param => fetch("GET", "/homePage/getCheckReportDetail", param);

/**
 * 首页 查看全部动态
 * @param
 */
export const selectListByPage = param => fetch("GET", "/sys/sysOperateLog/selectListByPage", param);

/**
 * 首页 查看全部公告
 * @param
 */
export const getSystemNoticeByPage = param => fetch("GET", "/cms/cmsCustomer/getSystemNoticeByPage", param);

/**
 * 首页 工单数据统计
 * @param
 */
export const getRepairOrder = param => fetch("GET", "/homePage/getRepairOrder", param);

/**
 * 首页 工单任务列表
 * @param
 */
export const workList = param => fetch("GET", "/orderTask/task/list", param);

/**
 * 首页 质检条数数据统计日期
 * @param
 */
export const getQualityOverViewNum = param => fetch("GET", "/homePage/getQualityOverViewNum", param);









